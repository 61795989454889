document.addEventListener("turbo:load", () => {
  const help = document.querySelector("#helpOfferings");
  if (help) {
    $(".collapse")
      .on("show.bs.collapse", function () {
        $(this)
          .parent()
          .find("i")
          .removeClass("fa-chevron-down")
          .addClass("fa-chevron-up");
      })
      .on("hide.bs.collapse", function () {
        $(this)
          .parent()
          .find("i")
          .removeClass("fa-chevron-up")
          .addClass("fa-chevron-down");
      });
  }
});
