if (document.querySelector("#retreatSearchForm")) {
  var selectedYear = document.getElementById("retreatSearchYear");
  var selectedYearValue = selectedYear.item(selectedYear.selectedIndex).value;
  if (selectedYearValue) {
    showOnlyVenuesForYear(selectedYearValue);
  }
  document
    .querySelector("#retreatSearchYear")
    .addEventListener("change", function () {
      showOnlyVenuesForYear(this.value);
    });

  function showOnlyVenuesForYear(year) {
    let searchVenueOptions = document.querySelectorAll(
      "#retreatSearchVenueId option"
    );
    Array.from(searchVenueOptions).map((option) =>
      option.removeAttribute("hidden")
    );

    if (!year) {
      return;
    }

    Array.from(searchVenueOptions).map((option) => {
      const yearsAttr = option.getAttribute("years");
      if (!yearsAttr.includes(year)) {
        option.setAttribute("hidden", true);
      }
    });

    resetSelector(document.getElementById("retreatSearchyear"));
  }

  var selectedVenue = document.getElementById("retreatSearchVenueId");
  var selectedVenueValue = selectedVenue.item(
    selectedVenue.selectedIndex
  ).value;
  if (selectedVenueValue) {
    showOnlyYearsForVenue(selectedVenue);
  }

  document
    .querySelector("#retreatSearchVenueId")
    .addEventListener("change", function () {
      showOnlyYearsForVenue(this);
    });

  function showOnlyYearsForVenue(venue) {
    let searchYearOptions = document.querySelectorAll(
      "#retreatSearchYear option"
    );
    Array.from(searchYearOptions).map((option) =>
      option.removeAttribute("hidden")
    );

    if (!venue) {
      return;
    }

    let yearsAttr = venue.options[venue.selectedIndex].getAttribute("years");

    Array.from(searchYearOptions).map((option) => {
      let year = option.value;
      if (!yearsAttr.includes(year)) {
        option.setAttribute("hidden", true);
      }
    });

    resetSelector(document.getElementById("retreatSearchVenueId"));
  }

  function resetSelector(element) {
    var select = document.getElementById("retreatSearchVenueId");
    var selectedItem = select.item(select.selectedIndex);

    if (selectedItem.getAttribute("hidden")) {
      select.selectedIndex = 0;
    }
  }

  document
    .querySelector("#retreat-search-filter")
    .addEventListener("click", function () {
      var selectedYear = document.getElementById("retreatSearchYear").value;
      var selectedVenue = document.getElementById("retreatSearchVenueId").value;
      Turbo.visit(
        "/retreats/?retreat_year=" +
          selectedYear +
          "&retreat_venue_id=" +
          selectedVenue
      );
    });
}
