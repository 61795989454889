$(document).ready(function(){

    /* https://stackoverflow.com/a/995193/1163042 */
    $.fn.inputFilter = function(inputFilter) {
        return this.on("input keydown keyup mousedown mouseup select contextmenu drop", function() {
            if (inputFilter(this.value)) {
                this.oldValue = this.value;
                this.oldSelectionStart = this.selectionStart;
                this.oldSelectionEnd = this.selectionEnd;
            } else if (this.hasOwnProperty("oldValue")) {
                this.value = this.oldValue;
                this.setSelectionRange(this.oldSelectionStart, this.oldSelectionEnd);
            }
        });
    };
    // restrict donation entry to numbers only
    $(".donate-amount").inputFilter(function(value) {
        return /^\d*$/.test(value);
    });

    $('#donate-form').on('submit', function(e){
        var i,
            summary = [],
            recipient_name,
            donation_total = 0,
            recipient_amount;

        // create a summary of each donation recipient and amount
        for (i = 0; i <= 5; i++) {
            recipient_name = $('input[name="recipient_' + i + '"]').val();
            recipient_amount = $('input[name="recipient_' + i + '_amount"]').val();
            if(Number(recipient_amount) > 0) {
                summary.push(recipient_name.substring(0, 25) + ': $' + recipient_amount);
            }
        }
        summary = summary.join(', ');
        /* console.log(summary); */
        $('input[name="item_name"]').val(summary);

        $('.donate-amount').each(function() {
            donation_total += Number($(this).val());
        });
        /* console.log(donation_total); */
        $('input[name="amount"]').val(donation_total);

        // if requested, set up monthly subscription vars
          // see https://developer.paypal.com/docs/classic/paypal-payments-standard/integration-guide/Appx-websitestandard-htmlvariables
        if($('input[name="recurring"]').prop("checked")) {
            $('input[name="cmd"]').val("_xclick-subscriptions");
            $('input[name="a3"]').val(donation_total); // subscription amount
            $('input[name="src"]').val(1); // enable recurring payments
            $('input[name="no_note"]').val(1); // notes aren't supported with subscriptions
        }
    });
});
