import Plyr from "plyr";
import Icons from "images/plyr.svg";

if ($("#audio-player")[0]) {
  const controls = $("#plyr-html").html();

  $("#plyr-html").remove();
  const player = new Plyr("#audio-player", {
    controls: controls,
    storage: { enabled: false },
    seekTime: 15,
    speed: {
      selected: 1,
      options: [0.9, 1, 1.25, 1.5, 1.75, 2],
    },
  });

  $(document).on("click", ".plyr__speed_value", function () {
    $("#plyr-speed").toggleClass("d-none");
  });

  $(document).on("click", "#plyr-speed .plyr__control", function () {
    $("#plyr-speed .plyr__control").attr("aria-checked", false);
    $(this).attr("aria-checked", true);
    $("#plyr-speed").addClass("d-none");

    let value = $(this).val();
    $(".plyr__speed_text").text(`${value}x`);
    player.media.playbackRate = value;
  });

  $(document).on("click", "#audio-player-close", function () {
    player.stop();
    player.source = {};
    player.download = null;
    $("#audio-player-wrapper").data("id", null);
    document.querySelector("#audio-player-wrapper").style.display = "none";
  });

  player.on("play", (event) => {
    setAllButtonUI();
  });

  player.on("pause", (event) => {
    setAllButtonUI();
  });

  function setAllButtonUI() {
    let audioContent = document.querySelectorAll(".js-audio-select");
    for (let i = 0; i < audioContent.length; i++) {
      setButtonUI($(audioContent[i]).find("i"), audioContent[i].dataset.id);
    }
  }

  function setButtonUI(element, id) {
    let activeTalkId = $("#audio-player-wrapper").data("id");
    let playClass = "fa-play";
    let playText = "Listen";
    let pauseClass = "fa-pause";
    let pauseText = "Pause";

    // reset all icons
    setButtonClassAndTitle(element, pauseClass, playClass, playText);
    if (activeTalkId == id && player.playing) {
      setButtonClassAndTitle(element, playClass, pauseClass, pauseText);
    }
  }

  function setButtonClassAndTitle(element, currentClass, newClass, newText) {
    element.removeClass(currentClass).addClass(newClass);
    if (element.siblings(".play-text")[0]) {
      element.siblings(".play-text").text(newText);
    } else {
      element.parent().attr("title", newText).tooltip("_fixTitle");
    }
  }

  document.addEventListener("turbo:load", function () {
    setAllButtonUI();
    let audioContent = document.querySelectorAll(".js-audio-select");
    for (let i = 0; i < audioContent.length; i++) {
      audioContent[i].addEventListener("click", function (e) {
        $("[data-hover='tooltip'],[data-toggle='tooltip']").tooltip("hide");

        e.preventDefault();
        if (this.dataset.id == $("#audio-player-wrapper").data("id")) {
          if ($(this).find("i").hasClass("fa-pause")) {
            player.pause();
          } else {
            player.play();
          }
          return;
        }
        document.querySelector("#audio-player-wrapper").style.display = "";

        player.source = {
          type: "audio",
          title: this.dataset.title,
          sources: [
            {
              src: this.dataset.url,
              type: this.dataset.type,
            },
          ],
        };

        let talkTitle = this.dataset.title;
        if (this.dataset.speakers) {
          talkTitle = `${this.dataset.speakers} - ${talkTitle}`;
        }
        document.querySelector("#audio-player-title").innerHTML = talkTitle;
        document.querySelector("#audio-player-title").href =
          "/talks/" + this.dataset.id;
        document.querySelector("#audio-player-wrapper .donate-button").href =
          "/speaker_donate?speakers=" + this.dataset.speakers + "#donate-form";

        $("#audio-player-wrapper").data("id", this.dataset.id);
        $("#plyrDownload").attr("href", this.dataset.downloadUrl);

        player.download = this.dataset.downloadUrl;
        player.play();
        return false;
      });
    }
  });
}
