document.addEventListener("turbo:load", () => {
  let sidebar = document.querySelector("#sidebar");
  let overlay = document.querySelector("#overlay");
  if (sidebar) {
    document.querySelector("#dismiss").addEventListener("click", function () {
      sidebar.classList.remove("active");
      overlay.classList.remove("active");
    });

    document.querySelector("#overlay").addEventListener("click", function () {
      sidebar.classList.remove("active");
      overlay.classList.remove("active");
    });

    document
      .querySelector("#sidebarCollapse")
      .addEventListener("click", function () {
        sidebar.classList.add("active");
        overlay.classList.add("active");
      });

    $(".sidebar-sublist")
      .on("show.bs.collapse", function () {
        $(this)
          .parent()
          .find("i")
          .removeClass("fa-chevron-down")
          .addClass("fa-chevron-up");
      })
      .on("hide.bs.collapse", function () {
        $(this)
          .parent()
          .find("i")
          .removeClass("fa-chevron-up")
          .addClass("fa-chevron-down");
      });

    document
      .querySelectorAll(".sidebar-sublist li a.active")
      .forEach((item) => {
        let parent = $(item).parents(".sidebar-sublist");
        parent.collapse("show");
      });
  }
});
