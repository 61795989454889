document.addEventListener("turbo:load", function () {
  $(function () {
    $("[data-hover='tooltip'],[data-toggle='tooltip']").tooltip();
  });
  const transcriptElement = document.querySelector("#js-transcript-button");
  if (transcriptElement) {
    transcriptElement.addEventListener("click", function () {
      $(this).blur();
    });
  }
});

document.addEventListener("turbo:before-cache", function () {
  $("[data-hover='tooltip'],[data-toggle='tooltip']").tooltip("dispose");
});
