document.addEventListener("turbo:load", () => {
  const courseAccordion = document.querySelector("#retreatClassAccordion");
  if (courseAccordion) {
    $(".collapse")
      .on("show.bs.collapse", function (e) {
        e.stopPropagation();
        $(this)
          .prev(".collapse-header") // directly target the preceding .collapse-header
          .find("i")
          .removeClass("fa-chevron-down")
          .addClass("fa-chevron-up");
      })
      .on("hide.bs.collapse", function (e) {
        e.stopPropagation();
        $(this)
          .prev(".collapse-header") // directly target the preceding .collapse-header
          .find("i")
          .removeClass("fa-chevron-up")
          .addClass("fa-chevron-down");
      });
  }
});
