document.addEventListener("turbo:load", () => {
  if (document.querySelector(".js-speaker-bio-read-more")) {
    document
      .querySelector(".js-speaker-bio-read-more")
      .addEventListener("click", function (e) {
        e.preventDefault();

        let parent = $(this).parents(".short-bio");
        let sibling = parent.siblings(".full-bio");
        parent.hide();
        sibling.removeClass("d-none");

        return false;
      });
  }
});
