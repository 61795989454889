import Rails from "@rails/ujs";

function debounce(func, wait, immediate) {
  var timeout;
  var requestPending = false;
  var waiting = !immediate;
  return function () {
    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      if (requestPending) func.apply(context, args);
      requestPending = false;
      waiting = !immediate;
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (waiting) {
      requestPending = true;
    } else {
      waiting = true;
      func.apply(context, args);
    }
  };
}

const querySpeakers = function () {
  var searchForm = document.getElementById("speakerSearchForm");
  Rails.fire(searchForm, "submit");
};

function init() {
  document.getElementById("speakerSearch").value = "";
}

document.addEventListener("turbo:load", () => {
  $(document).on("click", "table .speaker-row", function (e) {
    if ($(e.target).is("a,input"))
      // anything else you don't want to trigger the click
      return;
    Turbo.visit($(this).find("a").attr("href"));
  });

  var input = document.getElementById("speakerSearch");
  if (input) {
    input.addEventListener("input", debounce(querySpeakers, 200, true));
    window.onload = init;
  }
});
