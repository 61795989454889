import Plyr from "plyr";

document.addEventListener("turbo:load", () => {
  $(document).on("click", ".video-modal-link", function (e) {
    $("#audio-player-close").trigger("click");
    $("#videoModalName").text($(this).data("title"));
    $("#videoModal iframe").attr("src", $(this).data("embed-video-url"));
  });

  $("#videoModal").on("hide.bs.modal", function (e) {
    $("#videoModalName").text("");
    $("#videoModal iframe").attr("src", "");
  });
});
