import Litepicker from "litepicker";
import { DateTime } from "luxon";
import * as chrono from "chrono-node";
import "litepicker/dist/plugins/mobilefriendly";

document.addEventListener("turbo:load", () => {
  $("#searchAdvancedButton, .dropdown-close").click(function (event) {
    if (
      $("#searchAdvancedDropdown").is("hidden") ||
      $("#searchAdvancedDropdown").css("display") == "none"
    ) {
      $("#searchAdvancedDropdown").show();
    } else {
      $("#searchAdvancedDropdown").hide();
    }
  });
  $(".application-wrapper, .subheader-image").mousedown(function (event) {
    $(".dropdown-menu").hide();
  });

  let searchOptions = {
    singleMode: true,
    resetButton: true,
    maxDate: new Date(),
    format: {
      // parse function should return Date object
      // date - Date object or string (perhaps there will be more types, need to check)
      parse(date) {
        if (date instanceof Date) {
          return date;
        }
        if (typeof date === "string") {
          let newDate = chrono.parseDate(date);
          return newDate;
        }
        // from unix timestamp (eg.: new Date().getTime())
        if (typeof date === "number") {
          return new Date(date);
        }

        return new Date();
      },

      // date - Date object
      // output function should return string
      output(date) {
        return DateTime.fromJSDate(date).toLocaleString(DateTime.DATE_SHORT);
      },
    },
    autoRefresh: true,
    dropdowns: {
      minYear: 1985,
      months: true,
      years: true,
    },
    plugins: ["mobilefriendly"],
  };

  let searchStartDate = document.getElementById("searchStartDate");
  let searchEndDate = document.getElementById("searchEndDate");
  let startDatePicker, endDatePicker;
  if (searchStartDate && searchEndDate) {
    startDatePicker = new Litepicker({
      ...searchOptions,
      element: searchStartDate,
    });

    endDatePicker = new Litepicker({
      ...searchOptions,
      element: searchEndDate,
    });

    startDatePicker.on("selected", (date1) => {
      if (!endDatePicker.getDate()) {
        endDatePicker.gotoDate(date1);
      }
    });
  }

  var form = document.getElementById("search-form");
  if (form) {
    form.addEventListener(
      "submit",
      function (e) {
        e.preventDefault();
        let data = $("#search-form :input").filter(function () {
          return $(this).val() != "";
        });

        // format date to iso format to pass to server
        for (var item in data) {
          if (
            data[item].name == "start_date" ||
            data[item].name == "end_date"
          ) {
            data[item].value = DateTime.fromFormat(
              data[item].value,
              "D"
            ).toISODate();
          }
        }

        Turbo.visit("/playables/search?" + data.serialize());
      },
      true
    );
  }

  $(document).on("click", ".playable-search-button", function (e) {
    $(".playable-search-input").focus();
  });

  $(document).on("click", ".js-clear-form", function (e) {
    e.stopPropagation();
    startDatePicker.clearSelection();
    endDatePicker.clearSelection();
    $(form).find("input, textarea, select").val("");
  });
});
